import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Layout from "../components/Layout";

const Restaurants = ({ restaurants }) => {
  return (
    <Layout>
      <StaticQuery
        query={graphql`
          query {
            allStrapiRestaurant {
              edges {
                node {
                  strapiId
                  name
                  mainImage {
                    publicURL
                  }
                  cuisines {
                    name
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.allStrapiRestaurant.edges.map((r, i) => {
              console.log(r);
              return (
                <React.Fragment key={i}>
                  <h1>
                    <Link to={`/restaurants/${r.node.strapiId}`}>
                      {r.node.name}
                    </Link>
                  </h1>
                  {r.node.mainImage && (
                    <img src={r.node.mainImage.publicURL} alt="test" />
                  )}
                  {r.node.cuisines.map((c, cIndex) => {
                    return <p key={cIndex}>{c.name}</p>;
                  })}
                </React.Fragment>
              );
            })}
          </>
        )}
      ></StaticQuery>
    </Layout>
  );
};

export default Restaurants;
